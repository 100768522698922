@import "~@blueprintjs/core/dist/blueprint.css";

html {
  background: #f5f8fa;
}

body {
  margin: 0;
  padding: 0;
  color: #48576a;
  font-size: 14px;
  font-family: sans-serif;
}

p {
  margin-bottom: 5px;
}

#root {
  max-width: 960px !important;
  margin: 20px auto !important;
}

.text-view {
  margin: 20px;
}

.no-round {
  border-radius: 0 !important;
}

.grid-container {
}

.grid-left {
  width: 20%;
  margin-right: 5%;
  float: left;
}

.grid-right {
  width: 75%;
  float: right;
}

.fullscreen-loading {
  display: block !important;
  margin: 200px auto;
}

.btn-small {
  min-width: 20px;
  min-height: 20px;
  line-height: 20px;
  margin-right: 5px;
}

.default-quota {
  color: #aaa !important;
}

.block-element {
  display: block;
  width: 100%;
}

.pt-navbar-group .pt-button {
  margin-right: 2px;
}

.pt-card .pt-control:last-child {
  margin-bottom: 0;
}

.quota-used {
  position: absolute;
  right: 0;
  top: 7px;
}

.pt-icon-chevron-up {
  margin-left: 0!important;
  margin-top: 0!important;
}

.pt-icon-chevron-down {
  margin-left: 0!important;
  margin-top: 0!important;
}

.landing h1 {
  margin: 20px 0 40px;
}

.landing h2 {
   margin: 40px 0 20px;
}

.landing ol {
  padding: 0;
  list-style-position: inside;
  line-height: 1.6;
}

.landing .label {
  display: inline-block;
  min-width: 140px;
}

table.pt-table.no-border-top tbody tr:first-child td {
  box-shadow: none;
  -webkit-box-shadow: none;
}

table.pt-table .pt-control {
  margin-bottom: 0;
}
