.home {
  width: 1024px;
  position: relative;
  margin: -20px auto 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.logo {
  margin-left: 40px;
  padding-top: 20px;
}

.slogan {
  height: 647px;
  background: transparent url('./bg.png') left top no-repeat;
  background-size: 1024px 647px;
}

.introduce {
  color: #fff;
  font-size: 30px;
  width: 400px;
  font-weight: lighter;
  margin: 176px 0 0 112px;
}

.slogan nav {
  position: absolute;
  right: 0;
  top: 20px;
  text-transform: uppercase;
  padding-right: 40px;
}

.slogan nav a {
  display: inline-block;
  color: #fff;
  margin-left: 30px;
  font-size: 14px;
  font-weight: bold;
}

.slogan .iphone {
  background: transparent url('./iphone.png') left top no-repeat;
  width: 344px;
  height: 684px;
  background-size: 344px 684px;
  position: absolute;
  top: 100px;
  right: 80px;
}

.slogan button {
  width: 180px;
  height: 48px;
  border-radius: 24px;
  border: 0;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.slogan .purchase-btn {
  background: #fff;
  color: #5db4fa;
  margin-right: 20px;
}

.slogan .purchase-btn a {
  color: #5db4fa;
}

.slogan .contact-btn {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.slogan .contact-btn a {
  color: #fff;
}

.slogan .button-group {
  margin-top: 30px;
}

.description {
  padding-top: 140px;
  font-size: 24px;
  line-height: 1.7;
  background: #fff url('./sep.png') center bottom no-repeat;
  padding-bottom: 80px;
  background-size: 104px 24px;
}

.description p {
  margin: 0 auto;
  width: 800px;
  text-align: center;
  color: #2d2d32;
}

.feature-list {
  background: #fff;
  overflow: hidden;
  padding-top: 60px;
}

.features {
  float: left;
  width: 38%;
  margin: 0 6% 0 12%;
  position: relative;
  padding-left: 70px;
}

.features:last-child {
  margin-left: 0;
  margin-right: 0;
}

.features span {
  position: absolute;
  top: 0;
  left: 0;
}

.features p {
  line-height: 1.8;
  font-size: 13px;
  color: #4e4e52;
  width: 305px;
}

.features h3 {
  font-size: 18px;
  font-weight: bold;
  color: #2db4ff;
  line-height: 48px;
}

.feature-unified-profile {
  background: transparent url('./icon-unified-profile.png') left top no-repeat;
  background-size: 46px 48px;
}

.feature-security {
  background: transparent url('./icon-security.png') left top no-repeat;
  background-size: 46px 52px;
}

.feature-user-system {
  background: transparent url('./icon-user-system.png') left top no-repeat;
  background-size: 48px 42px;
}

.feature-license {
  background: transparent url('./icon-license.png') left top no-repeat;
  background-size: 50px 52px;
}

.platform {
  padding-top: 216px;
  height: 600px;
  background: transparent url('./platform-bg.png') left top no-repeat;
  background-size: 1024px 600px;
}

.platform .content-box {
  margin: 0 auto;
  width: 500px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 40px 0;
}

.platform .content-box h3 {
  line-height: 40px;
  text-align: center;
  font-size: 30px;
  font-weight: lighter;
  color: #fff;
}

.platform .content-box p {
  text-align: center;
  font-size: 18px;
  font-weight: lighter;
}

.contact {
  background: #fff;
  padding: 120px 0;
}

.contact h3 {
  font-weight: lighter;
  color: #2d2d32;
  font-size: 30px;
  width: 400px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.4;
}

.contact button {
  width: 180px;
  height: 48px;
  border-radius: 24px;
  border: 0;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background: #47affe;
  color: #fff;
  margin: 40px auto 0;
  display: block;
}

.contact button a{
  color: #fff;
}

.footer {
  padding: 40px;
  background: #47affe;
  position: relative;
  color: #fff;
}

.footer a {
  color: #fff;
}

.footer .twitter {
  position: absolute;
  right: 40px;
  top: 40px;
}
